import { useMutation } from "@apollo/client";
import { Alert } from "components/shared/Toast";
import { GENERATE_DOCUMENT } from "graphql/pricelist";
import { useState } from "react";
import http from "utils/http";

const useDownload = () => {
  const [downloading, setDownloading] = useState(false);
  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    onError(error) {
      Alert("error", error.mesesage);
    }
  });

  function download({ name, docType, data, fileType }) {
    setDownloading(true);
    generateDocument({
      variables: { name, docType, data }
    })
      .then(res =>
        http.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${
            res.data.generateDocument.document.id
          }/?${fileType}=1`,
          { responseType: "blob", withCredentials: true }
        )
      )
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name + "." + fileType);
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      })
      .catch(e => {
        Alert("error", e.message);
        setDownloading(false);
      });
  }

  return { download, downloading };
};

export default useDownload;
