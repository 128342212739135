import { useQuery } from "@apollo/client";
import { AppContext } from "App";
import ItemSize from "components/ItemSize";
import Button from "components/shared/Button";
import Errors from "components/shared/Errors";
import Spinner from "components/shared/Spinner";
import useDownload from "hooks/useDownload";
import { useContext } from "react";
import { FETCH_USER_PRICE_LIST } from "./graphql";

const PriceListPage = () => {
  const { user } = useContext(AppContext);
  const { loading, error, data } = useQuery(FETCH_USER_PRICE_LIST);
  const { download, downloading } = useDownload();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const priceList = data.me.mainCustomer.priceList;

  if (!priceList)
    return (
      <div className="text-base whitespace-nowrap overflow-auto flex flex-col p-10">
        <h1>Pricelist</h1>
        <div className="mt-4">
          No price list is setup for your account yet. Please contact us to get
          the latest price list.
        </div>
      </div>
    );

  function downloadPriceList() {
    download({
      name: "Price List - " + user.email,
      docType: "BIZ_PRICE_LIST",
      data: JSON.stringify(priceList),
      fileType: "xlsx"
    });
  }

  return (
    <div
      className="text-base whitespace-nowrap overflow-auto flex flex-col p-10"
      style={{
        height: `calc(100vh - 252px ${
          process.env.NODE_ENV === "production" ? "" : " - 28px"
        })`
      }}
    >
      <div className="flex items-end justify-between">
        <h1>Pricelist</h1>

        <div>
          <Button onClick={downloadPriceList} loading={downloading}>
            Download
          </Button>
        </div>
      </div>
      <div className="mt-6 text-sm overflow-auto flex-1 bg-white border">
        <table className="-translate-y-[1px]">
          <thead>
            <tr className="sticky top-0 bg-gray-100 z-30">
              <th className="opacity-100 border p-3 sticky top-0 left-0 bg-gray-100 z-30">
                Product
              </th>
              <th className="opacity-100 border text-right">Item Weight</th>
              <th className="opacity-100 border text-right">Item Size</th>
              <th className="opacity-100 border text-right">HS Code</th>
              <th className="opacity-100 border text-right">MOQ</th>
              <th className="opacity-100 border text-right">Inner Box Size</th>
              <th className="opacity-100 border text-right">Barcode EAN</th>
              <th className="opacity-100 border text-right">
                Quantity per Display Box
              </th>
              <th className="opacity-100 border text-right">
                Display Box Size
              </th>
              <th className="opacity-100 border text-right">
                Inner Carton Size
              </th>
              <th className="opacity-100 border text-right">
                Outer Carton Size
              </th>
              <th className="opacity-100 border text-right">CTN Net Weight</th>
              <th className="opacity-100 border text-right">
                CTN Gross Weight
              </th>
              <th className="opacity-100 border text-right">
                Outer Carton CBM
              </th>
              <th className="opacity-100 border text-right">Price</th>
            </tr>
          </thead>

          <tbody>
            {priceList.latestPrices.map(price => (
              <tr key={price.id} className="text-right ">
                <td className="text-left border px-3 sticky left-0 bg-gray-100 z-20">
                  <div>
                    <div className="flex space-x-4 items-center">
                      <div className="font-bold">{price.product.number}</div>
                      {price.product.images.length > 0 && (
                        <div className="flex space-x-2">
                          {price.product.images.map((image, index) => (
                            <img key={index} src={image.url} className="w-10" />
                          ))}
                        </div>
                      )}
                    </div>
                    <div>{price.product.name}</div>
                  </div>
                </td>
                <td className="border">{price.product.itemWeight} g</td>
                <td className="border">
                  <ItemSize {...price.product} unit="mm" />
                </td>
                <td className="border">
                  <div>{price.product.productLine?.hsCode}</div>
                  {price.product.productLine?.hsCodeForEu && (
                    <div>EU: {price.product.productLine.hsCodeForEu}</div>
                  )}
                  {price.product.productLine?.hsCodeForUs && (
                    <div>US: {price.product.productLine.hsCodeForUs}</div>
                  )}
                </td>
                <td className="border">{price.product.moq}</td>
                <td className="border">
                  <ItemSize
                    shape="cube"
                    cubeX={price.product.innerBoxX}
                    cubeY={price.product.innerBoxY}
                    cubeZ={price.product.innerBoxZ}
                    unit="mm"
                  />
                </td>
                <td className="border space-x-2">
                  {price.product.innerBoxBarcodeEan && (
                    <div>Inner Box: {price.product.innerBoxBarcodeEan}</div>
                  )}
                  {price.product.outerCartonBarcodeEan && (
                    <div>
                      Outer Carton: {price.product.outerCartonBarcodeEan}
                    </div>
                  )}
                </td>
                <td className="border space-x-2">
                  {price.product.quantityPerDisplayBox}
                </td>
                <td className="border">
                  <ItemSize
                    shape="cube"
                    cubeX={price.product.displayBoxX}
                    cubeY={price.product.displayBoxY}
                    cubeZ={price.product.displayBoxZ}
                    unit="mm"
                  />
                </td>
                <td className="border">
                  <ItemSize
                    shape="cube"
                    cubeX={price.product.innerCartonX}
                    cubeY={price.product.innerCartonY}
                    cubeZ={price.product.innerCartonZ}
                    unit="cm"
                  />
                </td>
                <td className="border">
                  <ItemSize
                    shape="cube"
                    cubeX={price.product.outerCartonX}
                    cubeY={price.product.outerCartonY}
                    cubeZ={price.product.outerCartonZ}
                    unit="cm"
                  />
                </td>
                <td className="border">{price.product.ctnNetWeight} kg</td>
                <td className="border">{price.product.ctnGrossWeight} kg</td>
                <td className="border">{price.product.outerCartonCbm} m³</td>
                <td className="border">
                  {priceList.currency} {price.price.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceListPage;
