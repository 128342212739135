import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import CatalogPage from "./pages/Catalog";
import ContactPage from "./pages/contact";
import TermsPrivacyPage from "./pages/TermsPrivacy";
import VideosPage from "./pages/videos";

import { gql, useQuery } from "@apollo/client";
import { AppContext } from "App";
import Spinner from "components/shared/Spinner";
import mixpanel from "mixpanel-browser";
import LoginPages from "pages/auth";
import RegisterPage from "pages/auth/RegisterPage";
import Landing2025 from "pages/Landing2025";
import LifestyleImages from "pages/LifestyleImages";
import Logos from "pages/Logos";
import OrderPage from "pages/orders";
import PriceListPage from "pages/priceList";
import Products from "pages/products/List";
import ProductDetailPage from "pages/products/ProductDetailPage";
import Footer from "./components/Footer";
import MainNav from "./components/MainNav";
import Catalog from "./pages/Catalog";
import ShipmentsPage from "./pages/shipments/index";

const FETCH_ME = gql`
  query FETCH_ME {
    me {
      id
      email
      firstName
      lastName
      isSuperuser
      isStaff
      groups {
        id
        name
      }
    }
  }
`;

function AppRoutes() {
  const { loading, data } = useQuery(FETCH_ME);
  const { setUser } = useContext(AppContext);
  const [inited, setInited] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname && process.env.NODE_ENV === "production")
      mixpanel?.track_pageview();
  }, [location]);

  useEffect(() => {
    if (data) {
      setUser(data.me);
      if (process.env.NODE_ENV === "production") mixpanel?.identify(data.me.id);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setUser(data.me);
      if (process.env.NODE_ENV === "production") mixpanel?.identify(data.me.id);
    }
  }, [data]);

  useEffect(() => {
    if (!loading) setInited(true);
  }, [loading]);

  if (loading || !inited) return <Spinner />;

  return (
    <Routes>
      <Route path="/login/*" element={<LoginPages />} />
      <Route path="/register" element={<RegisterPage />} />

      <Route element={<Layout />}>
        <Route path="/public/catalog" element={<Catalog />} />
        <Route path="/terms-privacy" element={<TermsPrivacyPage />} />
      </Route>
      <Route
        path="*"
        element={
          <Auth>
            <ProectedPages />
          </Auth>
        }
      />
    </Routes>
  );
}

const Auth = ({ children }) => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
};

const ProectedPages = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/landing" element={<Landing2025 />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:slug" element={<ProductDetailPage />} />
        <Route path="/price-list" element={<PriceListPage />} />
        <Route path="/catalog" element={<CatalogPage />} />
        <Route path="/lifestyle-images" element={<LifestyleImages />} />
        <Route path="/logos" element={<Logos />} />
        <Route path="/videos" element={<VideosPage />} />
        <Route path="/orders/*" element={<OrderPage />} />
        <Route path="/shipments/*" element={<ShipmentsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<Navigate to="/landing" />} />
      </Route>
    </Routes>
  );
};

const Layout = () => {
  return (
    <div className="relative z-20 text-sm flex flex-1 flex-col min-h-screen bg-gray-50">
      <div className="top-0 z-50">
        <MainNav />
      </div>
      <div className="flex-1 relative flex flex-col">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default AppRoutes;
