import { gql } from "@apollo/client";

export const FETCH_USER_PRICE_LIST = gql`
  query FETCH_USER_PRICE_LIST {
    me {
      id
      mainCustomer {
        id
        priceList {
          id
          currency
          latestPrices(prefetchProductFields: ["images", "product_line"]) {
            id
            product {
              id
              name
              number
              images: computedImages(size: "300x300") {
                url
              }

              itemWeight

              shape
              ballDiameter
              cubeX
              cubeY
              cubeZ
              cylindricalHeight
              cylindricalDiameter
              flatX
              flatY
              multiSizes

              productLine {
                hsCode
                hsCodeForEu
                hsCodeForUs
              }

              moq
              packing

              innerBoxX
              innerBoxY
              innerBoxZ

              displayBoxX
              displayBoxY
              displayBoxZ

              innerCartonX
              innerCartonY
              innerCartonZ

              outerCartonX
              outerCartonY
              outerCartonZ

              innerBoxBarcodeEan
              outerCartonBarcodeEan
              innerBoxBarcodeUpc
              outerCartonBarcodeUpc

              quantityPerDisplayBox

              ctnNetWeight
              ctnGrossWeight
              outerCartonCbm
            }
            price: finalPrice
          }
        }
      }
    }
  }
`;
