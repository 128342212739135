import { gql } from "@apollo/client";

export const GENERATE_DOCUMENT = gql`
  mutation GENERATE_DOCUMENT(
    $name: String!
    $docType: String!
    $data: String!
  ) {
    generateDocument(name: $name, docType: $docType, data: $data) {
      document {
        id
        name
        docType
        data
      }
    }
  }
`;
