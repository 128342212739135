import BackButton from "./BackButton";

const NavigationBar = ({ title, rightView, container, backTo = "./.." }) => {
  return (
    <nav className="py-4 sticky top-0 z-50 bg-gray-50 bg-opacity-80 backdrop-blur border-b border-opacity-60">
      <div
        className={`mx-auto px-6 flex justify-between items-center
            ${container ? "container" : ""}`}
      >
        <div className="flex items-center">
          <BackButton to={backTo} className="text-sky-600" />
          {title && <h3 className="ml-4">{title}</h3>}
        </div>
        {rightView}
      </div>
    </nav>
  );
};

export default NavigationBar;
