import React from "react";
import styled from "styled-components";
import ItemSize from "../../components/ItemSize";

const Cell = styled.div`
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
`;

function getHsCode(productLine) {
  if (!productLine) return "";
  return `${productLine.hsCode} ${
    productLine.hsCodeForEu ? `EU: ${productLine.hsCodeForEu}` : ""
  } ${productLine.hsCodeForUs ? `US: ${productLine.hsCodeForUs}` : ""}`;
}

function getMaterials(productLine, product) {
  // If product has children and at least one of them has materials, show them
  if (product && product.children && product.children.length > 0) {
    const materialsList = product.children
      .filter(p_m => p_m.product.materials.length > 0)
      .map(
        p_m =>
          `- ${p_m.product.name}: ${p_m.product.materials
            .map(m => m.material.name)
            .join(", ")}`
      )
      .join("\n");

    if (materialsList) {
      return materialsList;
    }
  }

  // otherwise show product materials
  if (product && product.materials.length > 0) {
    return product.materials.map(i => i.material.name).join(", ");
  }

  // if product has no materials, show the product line materials similarly

  if (productLine && productLine.children) {
    if (productLine.children.length > 0) {
      return productLine.children
        .map(
          i =>
            `- ${i.name}: ${i.materials.map(j => j.material.name).join(", ")}`
        )
        .join("\n");
    }
    return productLine.materials.map(i => i.material.name).join(", ");
  }
  return " - ";
}

const FIELDS = [
  "Item",
  "Item Number",
  "Product Weight (+-5%)",
  "Product Size (+-5%)",
  "HS Code",
  "Product Materials",
  "MOQ",
  "Packing",
  "Inner Box/Clam Shel Size (+-5%)",
  "Barcode EAN",
  "Barcode UPC",
  "Quantity per Displaybox",
  "Display Box Size (+-5%)",
  "Inner Carton Size (+-5%)",
  "Outer Carton Size (+-5%)",
  "CTN Net Weight (+-5%)",
  "CTN Gross Weight (+-5%)",
  "Outer Carton CMB (+-5%)",
  "Quantity per Carton"
];

const ProductData = ({ items, productLine }) => {
  return (
    <div className="flex relative overflow-auto border-x">
      <table className="w-full whitespace-nowrap">
        <tbody>
          {FIELDS.map((field, index) => (
            <tr key={index}>
              <td className="border-y sticky left-0 z-10 bg-gray-50 whitespace-nowrap">
                {field}
              </td>
              {items.map(i => (
                <td key={i.id} className="border">
                  {field === "Item" ? i.name : ""}
                  {field === "Item Number" ? i.number : ""}
                  {field === "Product Weight (+-5%)" ? `${i.itemWeight} g` : ""}
                  {field === "Product Size (+-5%)" ? (
                    <ItemSize {...i} unit="mm" />
                  ) : (
                    ""
                  )}
                  {field === "HS Code" ? getHsCode(productLine) : ""}
                  {field === "Product Materials"
                    ? getMaterials(productLine, i)
                    : ""}
                  {field === "MOQ" ? i.moq : ""}
                  {field === "Packing" ? i.packing : ""}
                  {field === "Inner Box/Clam Shel Size (+-5%)" ? (
                    <ItemSize
                      shape="cube"
                      cubeX={i.innerBoxX}
                      cubeY={i.innerBoxY}
                      cubeZ={i.innerBoxZ}
                      unit="mm"
                    />
                  ) : (
                    ""
                  )}
                  {field === "Barcode EAN"
                    ? `Inner Box: ${i.innerBoxBarcodeEan ||
                        " - "} Outer Carton: ${i.outerCartonBarcodeEan ||
                        " - "}`
                    : ""}
                  {field === "Barcode UPC"
                    ? `Inner Box: ${i.innerBoxBarcodeUpc ||
                        " - "} Outer Carton: ${i.outerCartonBarcodeUpc ||
                        " - "}`
                    : ""}
                  {field === "Quantity per Displaybox"
                    ? i.quantityPerDisplayBox
                    : ""}
                  {field === "Display Box Size (+-5%)" ? (
                    <ItemSize
                      shape="cube"
                      cubeX={i.displayBoxX}
                      cubeY={i.displayBoxY}
                      cubeZ={i.displayBoxZ}
                      unit="mm"
                    />
                  ) : (
                    ""
                  )}
                  {field === "Inner Carton Size (+-5%)" ? (
                    <ItemSize
                      shape="cube"
                      cubeX={i.innerCartonX}
                      cubeY={i.innerCartonY}
                      cubeZ={i.innerCartonZ}
                      unit="cm"
                    />
                  ) : (
                    ""
                  )}
                  {field === "Outer Carton Size (+-5%)" ? (
                    <ItemSize
                      shape="cube"
                      cubeX={i.outerCartonX}
                      cubeY={i.outerCartonY}
                      cubeZ={i.outerCartonZ}
                      unit="cm"
                    />
                  ) : (
                    ""
                  )}
                  {field === "CTN Net Weight (+-5%)"
                    ? `${i.ctnNetWeight} kg`
                    : ""}
                  {field === "CTN Gross Weight (+-5%)"
                    ? `${i.ctnGrossWeight || " - "} kg`
                    : ""}
                  {field === "Outer Carton CMB (+-5%)"
                    ? `${i.outerCartonCbm} m³`
                    : ""}
                  {field === "Quantity per Carton" ? i.quantityPerCarton : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductData;
